import React from 'react'
import {MdPhone as PhoneIcon} from 'react-icons/md'
import NoSSR from '@components/NoSSR'
import Container from '@packages/justo-parts/lib/components/Container'
import Tooltip from '@packages/justo-parts/lib/components/Tooltip'

import Address from './Address'
import Image from './Image'
import Text from './Text'

import styles from './styles.module.css'

export default function Stores(props: {
  title: string
  stores: any[]
}) {
  return (
    <div className={styles.container}>
      <Container>
        {props.title ? <div className={styles.title}>{props.title}</div> : null}
        <div className="row center-xs">
          {props.stores.map((store, index) => (
            <div key={index} className="col-xs-12 col-sm-6 col-md-3">
              <div className={styles.store}>
                {store.image ? (
                  <Image file={store.image} />
                ) : null}
                <div className={styles.inner}>
                  <div className={styles.name}>{store.name}</div>
                  {store.phone ? (
                    <div className={styles.phone}>
                      <NoSSR>
                        <Tooltip content="Llamar">
                          <PhoneIcon style={{marginRight: 8, top: -1, position: 'relative'}} />
                          <a href={'tel:' + encodeURIComponent(store.phone)}>{store.phone}</a>
                        </Tooltip>
                      </NoSSR>
                    </div>
                  ) : null}
                  <Address
                    placeId={store.placeId}
                  />
                  <Text store={store} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}
