import React from 'react'
import useResizedImageUrlSsr from '@hooks/useResizedImageUrlSsr.main'

import styles from './styles.module.css'

export default function Image(props: {file?: {_id: string}; }) {
  const {file} = props
  const url = useResizedImageUrlSsr({
    fileId: file ? file._id : null,
    width: 500,
    height: 300
  })

  if (!file) return null

  // Server response can be more up to date from what the client has in its local cache
  return <img suppressHydrationWarning className={styles.image} src={url} alt="" />
}
