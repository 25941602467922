import React, {Fragment} from 'react'

import styles from './styles.module.css'

export default function Text(props) {
  if (!props.store.otherText) return null
  return (
    <div className={styles.container}>
      {props.store.otherText.split('\n').map((line, index) => (
        <Fragment key={index}>
          {line}
          <br />
        </Fragment>
      ))}
    </div>
  )
}
