import {useSuspenseQuery} from '@apollo/client'
import NoSSR from '@components/NoSSR'
import {
  GetPlaceDetails_CachedQuery,
  GetPlaceDetails_CachedQueryVariables,
} from '@data/__generated__/types.main'
import {placeDetailQuery} from '@data/queries/components/placeDetail.main'
import isMobile from '@helpers/misc/isMobile'
import Tooltip from '@packages/justo-parts/lib/components/Tooltip'
import {MdDirections} from 'react-icons/md'

import useInitialData from '@page-components/Layout/useInitialData'
import styles from './styles.module.css'

export default function Address({
  placeId,
}: {
  placeId: string
}) {
  const {data} = useSuspenseQuery<
    GetPlaceDetails_CachedQuery,
    GetPlaceDetails_CachedQueryVariables
  >(placeDetailQuery, {
    variables: {placeId},
    fetchPolicy: 'cache-first',
  })
  const {website} = useInitialData()

  const place = data?.place
  const onClick = () => {
    const query =
      place?.location?.lat && place?.location?.lng
        ? `${place.location.lat},${place.location.lng}`
        : encodeURI(website.name)
    const url = `https://www.google.com/maps/search/?api=1&query=${query}`
    if (isMobile) {
      window.location.href = url
    } else {
      window.open(url)
    }
  }
  return (
    <div className={styles.container} onClick={onClick}>
      <NoSSR>
        <Tooltip content="Abrir en Google Maps">
          <MdDirections style={{marginRight: 8, top: -1, position: 'relative'}} />
          {place ? place.text : 'Ver en Google Maps'}
        </Tooltip>
      </NoSSR>
    </div>
  )
}
