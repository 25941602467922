import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

import {
  getPlaceDetails_cached,
  getPlaceDetails_cachedVariables
} from './__generated__/getPlaceDetails_cached'

export const placeDetailQuery = gql`
  query getPlaceDetails_cached($placeId: ID) {
    place(placeId: $placeId) {
      _id
      text
      secondaryText
      location
    }
  }
`
export default async function getPlaceDetail(placeId: string) {
  const {data} = await getClient().query<getPlaceDetails_cached, getPlaceDetails_cachedVariables>({
    query: placeDetailQuery,
    variables: {
      placeId
    }
  })

  return data.place
}
